<template>
  <v-card
    v-if="result"
    :loading="loading"
  >
    <v-card-title class="d-flex justify-space-between">
      <span>{{'ga_report.overview.title'| t}}</span>

      <v-tooltip top>
        <template #activator="{on}">
          <v-btn v-on="on" icon @click="loadReportData(true)">
            <v-icon class="fa fa-sync-alt"></v-icon>
          </v-btn>
        </template>

        <div>{{'ga_report.action.force_update'| t}}</div>
      </v-tooltip>
    </v-card-title>

    <v-card-subtitle>
      <div>{{'ga_report.overview.subtitle'| t}}</div>
      <div>{{'data.updated_at'| t}}: {{result.updated_at}}</div>
    </v-card-subtitle>

    <v-divider></v-divider>
    <v-list
      max-height="500"
      class="overflow-auto"
    >
      <v-list-item
        v-for="(item, index) in result.data"
        :key="`data-${index}`"
      >
       <v-list-item-content>
         <v-list-item-title class="text-truncate">
           {{`${(index+1)}.`}} {{item.pageTitle}}
         </v-list-item-title>

         <v-list-item-subtitle>
           {{'ga_report.data.pageview'| t}}: {{item.pageView}}
         </v-list-item-subtitle>
       </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script lang="babel" type="text/babel">
export default {
  data: () => ({
    result: null,
    loading: false,
  }),
  created() {
    this.loadReportData()
  },
  methods: {
    async loadReportData(force = false) {
      this.loading = true
      try {
        this.result = await this.$api.collection.gaReportApi.pageview({
          force: force === true ? 1 : 0,
        })
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
  },
  computed: {
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
